












import {Component, Prop, Vue} from 'vue-property-decorator';
import DateFilters from '@/mixins/date-filters.mixin';
import { Headline } from '@/model/article/article.model';

@Component({
  mixins: [DateFilters],
  components: {},
})
export default class BackgroundSideList extends Vue {
  @Prop({required: true}) headlines!: Headline[];

}
