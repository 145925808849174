

















import {Component, Vue} from 'vue-property-decorator';
import { Prop } from 'vue-property-decorator';
import {GameContentType} from '@/model/site/components.model';

@Component({
  components: {},
})
export default class GameResult extends Vue {
  @Prop({required: true, type: String}) contentType!: GameContentType;
  @Prop({required: true, type: String}) gameId!: string;
  @Prop({required: true}) resultOrTime!: string;
  @Prop({required: false}) periods!: string;
}
