
















import {Component, Prop, Vue} from 'vue-property-decorator';
import LoremIpsum from '@/components/test/LoremIpsum.vue';
import {Topstory} from '@/model/article/article.model';
import S from 'string';
import BMessage from 'buefy/src/components/message/Message.vue';
import TopstoryContent from '@/components/news/TopstoryContent.vue';

// TODO [feature] support limits from `src/website/config/configHockeyMain.php`

@Component({
  components: {TopstoryContent, BMessage, LoremIpsum},
})
export default class TopstoryDisplay extends Vue {

  @Prop(Object) topstory!: Topstory;
  @Prop({default: false}) hideImage?: boolean;

  get hasImage(): boolean {
    if (this.hideImage) {
      return false;
    }
    return !S(this.topstory.image).isEmpty();
  }

  get imageWrapperClass(): string[] {
    return this.hasImage ? ['hf-wrapper is-gapless'] : [];
  }

  get overlay(): string[] {
    const classes = ['hf-overflay-base'];
    if (this.hasImage) {
      classes.push('hf-overlay-picture');
    }
    return classes;
  }

}
