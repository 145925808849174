import Vue from 'vue';
import Component from 'vue-class-component';
import {IPlayerBirthday} from '@/model/core/player.model';

@Component({
	filters: {
		others: function (players: IPlayerBirthday[], last: number) {
			return players.slice(-last).map(p => `${p.firstName} ${p.lastName} (${p.ageInYears}${p.teamDisplay ? ' • ' + p.teamDisplay : ''})`).join(', ');
		}
	},
})
export default class PlayersBirthdayTooltipFilters extends Vue {

}
