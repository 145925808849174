import {GameTypeId, LeagueId} from '@/model/core/core-data.model';

export interface Context {
	leagueId: LeagueId;
	groupId: string;
	focusDate: Date;
	focusSeasonId: string;
	focusLeagueId: LeagueId;
	focusGameTypeId: GameTypeId;
	focusTeamId: string | undefined;
	favouriteTeamId: string | undefined;
	configuration: any;
	refreshable: boolean;
	warnings: string[];
}

export enum GameMode {
	BOTH = 'both',
	NEXT = 'next',
	LAST = 'last',
	SAME_TEAM = 'sameTeam',
	DATE = 'date',
}

export interface SiteConfig {
	loaderDelay: number;
	healthCheckTimeout: number;
	healthCheckTimeoutDisabled: boolean;
	standaloneStatsMode: boolean;
	urlForum: string;
}

export interface ForumConfig {
	redirectTimeoutSeconds: number;
	redirectUrl: string;
	includeUrl: string;
}
