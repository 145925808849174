















































import {Component, Prop, Vue} from 'vue-property-decorator';
import Player, {IPlayerBirthday} from '@/model/core/player.model';
import DateFilters from '@/mixins/date-filters.mixin';
import PlayersBirthdayTooltipFilters from '@/mixins/players-birthday-tooltip-filters.mixin';
import HfTeamLogo from '@/components/shared/HfTeamLogo.vue';
import {playerStatsLink} from '@/router';

@Component({
  components: {HfTeamLogo},
  mixins: [DateFilters, PlayersBirthdayTooltipFilters],
})
export default class BirthdayBox extends Vue {

  @Prop({required: true}) players!: IPlayerBirthday[];
  @Prop({required: true, type: Number}) limit!: number;
  @Prop({required: true, type: Number}) total!: number;
  @Prop({required: true, type: Number}) pictures!: number;

  playerStatsLink(player: IPlayerBirthday) {
    return playerStatsLink(player.playerId);
  }

}
