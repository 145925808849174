














import {Component, Prop, Vue} from 'vue-property-decorator';
import {GameInfo} from '@/model/game/game.model';
import CarouselGameDisplay from '@/components/games/CarouselGameDisplay.vue';

@Component({
  components: {CarouselGameDisplay}
})
export default class GameCarousel extends Vue {
  @Prop({required: true, type: Array}) games?: GameInfo[];
}
