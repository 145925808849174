



































import {Component, Inject, Vue, Watch} from 'vue-property-decorator';
import TopstoryDisplay from '@/components/news/TopstoryDisplay.vue';
import GameCarousel from '@/components/games/GameCarousel.vue';
import NewsArticle from '@/components/news/NewsArticle.vue';
import HfAdBox from '@/components/ads/HfAdBox.vue';
import {ArticleService} from '@/services/article.service';
import {GameService} from '@/services/game.service';
import {Article, Headline, Topstory} from '@/model/article/article.model';
import {Getter} from 'vuex-class';
import EventRanking from '@/components/event/EventRanking.vue';
import {Context, GameMode} from '@/model/site/context.model';
import {Box, ImageDescription} from '@/model/site/components.model';
import EventGames from '@/components/event/EventGames.vue';
import TopGame from '@/components/event/TopGame.vue';
import {GameInfo, IndexedGames} from '@/model/game/game.model';
import ContextLeagueSelection from '@/components/ui/ContextLeagueSelection.vue';
import {
  CONTEXT,
  INDEX_CONFIG,
  SELECT_LEAGUE_ID,
  SELECTED_LEAGUE_ID
} from '@/store/context.store';
import {SET_TOPGAME} from '@/store/data/mutations';
import {gamesByDate, LASTGAMES, LEAGUE_IDS, NEXTGAMES} from '@/store/data/getters';
import {LeagueId, sortLeagueIds} from '@/model/core/core-data.model';
import NewsHeadlines from '@/components/news/NewsHeadlines.vue';
import {extractLeagueIds} from '@/store/data';
import RouteMixin from '@/mixins/route.mixin';
import MetaMixin from '@/mixins/meta.mixin';
import {HF_BASE} from '@/utils/common.util';
import NewsColumns from '@/components/news/NewsColumns.vue';
import {LOAD_CURRENT_GAMES} from '@/store/data/actions';
import {GET_FAVOURITE_TEAM_ID} from '@/store/user/getters';
import SportLayout from '@/components/layout/SportLayout.vue';
import SideBoxes from '@/components/layout/SideBoxes.vue';
import {BackgroundArticleService} from '@/services/background-article.service';
import BackgroundSideList from '@/components/background/BackgroundSideList.vue';
import SelectionTitle from '@/components/ui/SelectionTitle.vue';
import NewsHeadline from '@/components/news/NewsHeadline.vue';
import CarouselGameDisplay from '@/components/games/CarouselGameDisplay.vue';
import {Carousel, Slide} from '@noim/vue-carousel';
import BirthdayBox from '@/components/player/BirthdayBox.vue';
import PLAYER_SERVICE from '@/services/player.service';
import {IPlayerBirthday} from '@/model/core/player.model';
import PlayerPicture from '@/components/player/PlayerPicture.vue';
import HfTeamLogo from '@/components/shared/HfTeamLogo.vue';
import GoldbachOutstream from '@/components/ads/GoldbachOutstream.vue';
import MobileMixin from '@/mixins/mobile.mixin';
import StaticContent from '@/pages/static/StaticContent.vue';
import RefreshPageMixin, { HfRefreshablePage } from '@/mixins/refreshable-page.mixin';


@Component({
  components: {
    StaticContent,
    GoldbachOutstream,
    HfTeamLogo,
    PlayerPicture,
    NewsHeadline,
    SelectionTitle,
    SideBoxes,
    SportLayout,
    NewsColumns,
    ContextLeagueSelection,
    EventGames,
    EventRanking,
    HfAdBox,
    NewsArticle,
    GameCarousel,
    TopstoryDisplay,
    NewsHeadlines,
    CarouselGameDisplay,
    Carousel,
    Slide
  },
  mixins: [MetaMixin, RouteMixin, MobileMixin, RefreshPageMixin],
  metaInfo() {
    return this.metaInfo;
  },
})
export default class IndexPage extends Vue implements HfRefreshablePage {

  get headTitle() {
    return 'News, Statistiken, Forum zum Schweizer Eishockey';
  }

  get headDescription() {
    return 'hockeyfans.ch ist die umfassendste und aktuellste Schweizer Eishockeyseite mit News, Hintergrundbeiträgen, Diskussionen, ' +
      'Berichten und Statistiken zu allen Spielen der National League und Swiss League sowie sämtlichen internationalen Turnieren.';
  }

  get canonicalUrl(): string {
    return HF_BASE;
  }

  get imageDesc(): ImageDescription | undefined {
    if (this.topstory) {
      if (this.topstory.pictureURL) {
        return {src: this.topstory.pictureURL, alt: ''};
      }
      if (this.topstory.image) {
        return {src: this.topstory.image, alt: ''};
      }
      if (this.topstory.playerImage) {
        return {src: this.topstory.playerImage, alt: ''};
      }
    }
    return undefined;
  }

  @Inject('articleService') articleService!: ArticleService;
  @Inject('gameService') gameService!: GameService;
  @Inject('backgroundArticleService') backgroundArticleService!: BackgroundArticleService;

  @Getter(CONTEXT) context!: Context;
  @Getter(INDEX_CONFIG) indexConfig!: any;

  @Getter(LASTGAMES) lastgames!: IndexedGames;
  @Getter(NEXTGAMES) nextgames!: IndexedGames;

  @Getter(SELECTED_LEAGUE_ID) selectedLeagueId!: LeagueId;
  @Getter(LEAGUE_IDS) leagueIds!: LeagueId[];
  @Getter(GET_FAVOURITE_TEAM_ID) favouriteTeamId!: string;

  topstory: Topstory = {} as Topstory;
  news: Article[] = [];
  backgroundHeadlines: Headline[] = [];
  playerBirthdays: IPlayerBirthday[] = [];

  get boxes(): Box[] {
    const leagueSelection: Box = {
      component: ContextLeagueSelection,
      props: {gapless: true, wide: true}
    };
    const topGame: Box = {
      component: TopGame,
      props: {gapless: true, wide: true}
    };
    const currentGames: Box = {
      title: this.currentLeagueDesc,
      component: EventGames,
      props: {
        mode: GameMode.BOTH,
        lastLeagueGames: this.lastLeagueGames,
        nextLeagueGames: this.nextLeagueGames,
      },
    };
    const ranking: Box = {
      component: EventRanking,
    };
    const bookShop: Box = {
      component: StaticContent,
      title: 'Bücher-Shop',
      props: {
        staticId: 'buchpromo'
      }
    };
    let playerBirthdayLimit = this.indexConfig.players.birthdays;
    const playerBirthdayTotal = this.playerBirthdays.length;
    if (playerBirthdayTotal - playerBirthdayLimit === 1) {
      playerBirthdayLimit++;
    }
    const birthdays: Box = {
      title: '🎂 Heute feiern Geburtstag 🎂',
      component: BirthdayBox,
      props: {
        gapless: false,
        players: this.playerBirthdays,
        limit: playerBirthdayLimit,
        total: playerBirthdayTotal,
        pictures: this.indexConfig.players.birthdayPictues
      }
    };
    const background: Box = {
      title: 'Background',
      component: BackgroundSideList,
      props: {headlines: this.backgroundHeadlines}
    };
    const ad: Box = {
      component: HfAdBox,
      props: {formatKey: 'rectangle', targets: ['desktop']},
    };
    return [topGame, leagueSelection, currentGames, ranking, ad, background, birthdays, bookShop];
  }

  get mainNews(): Article[] {
    return this.news.slice(0, this.indexConfig.news.main.cnt);
  }

  get otherNews(): Article[] {
    return this.news.slice(this.indexConfig.news.main.cnt,
      this.indexConfig.news.main.cnt + this.indexConfig.news.other.cnt);
  }

  get latestHeadlines(): Headline[] {
    return this.news;
  }

  get lastLeagueGames(): IndexedGames {
    return gamesByDate(this.lastgames, this.context.leagueId, this.indexConfig.games.last);
  }

  get nextLeagueGames(): IndexedGames {
    return gamesByDate(this.nextgames, this.context.leagueId, this.indexConfig.games.next);
  }

  get currentLeagueDesc() {
    return this.$t(`leagueIdLong.${this.selectedLeagueId}`).toString();
  }

  get currentLeagueGames(): GameInfo[] {
    const leagueId = this.selectedLeagueId;
    return this.games
      .filter(g => g.leagueId === leagueId && g.result)
      .sort((g1, g2) => g2.timestamp - g1.timestamp);
  }

  @Watch(LEAGUE_IDS)
  onLeagueIdsChange(newLeagueIds: LeagueId[]) {
    const leaguesWithResults = newLeagueIds.filter(l => {
      return this.games.filter(g => g.leagueId === l && g.result).length > 0;
    });
    if (leaguesWithResults.length > 0) {
      const leagueId = sortLeagueIds(leaguesWithResults)[0];
      this.$store.commit(SELECT_LEAGUE_ID, leagueId);
    }
  }

  @Watch('favouriteTeamId')
  onFavouriteTeamIdChange() {
    this.loadFavourites();
  }

  created() {
    this.refreshPage();
  }

  refreshPage() {
    const nrOfNews = this.indexConfig.news.main.cnt
      + this.indexConfig.news.other.cnt
      + this.indexConfig.news.addHeadlines.cnt;
    this.articleService.latest(nrOfNews)
      .then((news: Article[]) => this.news = this.newsWithoutTopstory(news, this.topstory))
      .catch((error: any) => Vue.$log.error('Error fetching latest news', error));
    this.loadFavourites();

    const currentGames = this.$store.dispatch(LOAD_CURRENT_GAMES);
    const backgroundHeadlines = this.backgroundArticleService.headlines(this.indexConfig.background.headlines);
    const playerBirthdays = PLAYER_SERVICE.birthdayTodays();
    const dataPromises = [currentGames, backgroundHeadlines, playerBirthdays];
    Promise.all(dataPromises)
      .then((data) => {
        // this.currentGames = data[0]; // data is set use Vuex
        this.backgroundHeadlines = data[1];
        this.playerBirthdays = data[2];
      })
      .catch((e: any) => Vue.$log.warn(e));
  }

  loadFavourites() {
    this.$wait.start('topstory');
    this.articleService.topstory(this.favouriteTeamId)
      .then((topstory: Topstory) => {
          this.topstory = topstory;
          if (this.topstory && this.news) {
              this.news = this.newsWithoutTopstory(this.news, this.topstory);
          }
      })
      .catch(() => {})
      .finally(() => this.$wait.end('topstory'));

    this.$wait.start('topgame');
    this.gameService.topgame(this.favouriteTeamId)
      .then((game: any) => this.$store.commit(SET_TOPGAME, game))
      .catch(() => {})
      .finally(() => this.$wait.end('topgame'));
  }

  newsWithoutTopstory(news: Article[], topstory: Topstory): Article[] {
    if (!topstory || !topstory.id) {
      return news;
    }
    return news.filter(n => n.id.toString() !== topstory.id.toString());
  }

  get games(): GameInfo[] {
    return this.extractGames(this.lastgames);
  }

  extractGames(data: IndexedGames): GameInfo[] {
    const validLeagueIds = extractLeagueIds(data);
    let games: any[] = [];
    validLeagueIds.forEach(leagueId => {
      if (data[leagueId]) {
        games = [...games, ...data[leagueId]];
      }
    });
    return games;
  }

}
