



































































import {Component, Prop, Vue} from 'vue-property-decorator';
import {gameContentType, GameInfo, roundInfo} from '@/model/game/game.model';
import HfTeamLogo from '@/components/shared/HfTeamLogo.vue';
import GameLinks from '@/components/event/GameLinks.vue';
import DateFilters from '@/mixins/date-filters.mixin';
import GameResult from '@/components/game/GameResult.vue';
import {GameContentType} from '@/model/site/components.model';

@Component({
  components: {GameResult, HfTeamLogo, GameLinks},
  mixins: [DateFilters],
})
export default class CarouselGameDisplay extends Vue {
  @Prop(Object) game!: GameInfo;
  @Prop(Boolean) periods!: boolean;

  readonly gameContentTypeTelegram = GameContentType.TELEGRAMM;
  readonly gameContentTypeBericht = GameContentType.BERICHT;

  get subtitle() {
    return `${this.game.homeTeamNameShort} vs. ${this.game.awayTeamNameShort}`;
  }

  get showScorers() {
    return this.game.hasData && (this.game.scorersHome || this.game.scorersAway);
  }

  roundInfo(game: GameInfo) {
    return roundInfo(this, game, true);
  }

  contentTypeForGame(game: GameInfo) {
    return gameContentType(game);
  }

}
