


























import {Component, Prop, Vue} from 'vue-property-decorator';
import {Topstory} from '@/model/article/article.model';
import {ROUTING_UTILS} from '@/router';
import {Content} from '@/utils/content.util';
import Loading from 'vue-loading-overlay';
import ShareThis from '@/components/shared/social/ShareThis.vue';
import RouteMixin from '@/mixins/route.mixin';

@Component({
  components: {ShareThis, Loading},
  mixins: [RouteMixin]
})
export default class TopstoryContent extends Vue {

  @Prop(Object) topstory!: Topstory;
  @Prop({required: true}) showBody?: boolean;

  get detailLink() {
    const path = ROUTING_UTILS.pathForTopstory(this.topstory.type, this.topstory.id);
    return path ? path : undefined;
  }

  get body(): string {
    let body = this.topstory.body;
    if (Array.isArray(body)) {
      body = body.join('');
    }
    return body ? Content.stripTags(body) : '';
  }

}
