











































































import {Component, Prop, Vue} from 'vue-property-decorator';
import {Getter} from 'vuex-class';
import {gameContentType, GameInfo, IndexedGames} from '@/model/game/game.model';
import {GameMode} from '@/model/site/context.model';
import GameLinks from '@/components/event/GameLinks.vue';
import {CURRENT_GAME_ID} from '@/store/data/getters';
import {hasKeys} from '@/utils/common.util';
import MatchdayLinks from '@/components/games/MatchdayLinks.vue';
import {GameContentType} from '@/model/site/components.model';

@Component({
  components: {MatchdayLinks, GameLinks},
})
export default class EventGames extends Vue {

  @Prop({default: GameMode.BOTH}) mode!: GameMode;
  @Prop(Object) lastLeagueGames!: IndexedGames;
  @Prop(Object) nextLeagueGames!: IndexedGames;
  @Prop(Object) gamesAtDate!: IndexedGames;
  @Prop(Boolean) showMatchdayLink?: boolean;

  @Getter(CURRENT_GAME_ID) currentGameId?: string;

  isSameGame(gameId: string): boolean {
    return this.currentGameId === gameId;
  }

  contentTypeForGame(game: GameInfo): GameContentType {
    return gameContentType(game);
  }

  get isShowGamesAtDate(): boolean {
    return this.mode === GameMode.DATE && typeof this.gamesAtDate === 'object';
  }

  get isShowLastgames(): boolean {
    return (this.mode === GameMode.BOTH || this.mode === GameMode.LAST) && typeof this.lastLeagueGames === 'object';
  }

  get isShowNextgames(): boolean {
    return (this.mode === GameMode.BOTH || this.mode === GameMode.NEXT) && typeof this.nextLeagueGames === 'object';
  }

  get hasLastGames(): boolean {
    return hasKeys(this.lastLeagueGames );
  }

  get hasNextGames(): boolean {
    return hasKeys(this.nextLeagueGames);
  }

  get hasNextOrLastGames(): boolean {
    return this.hasNextGames || this.hasLastGames;
  }

  get hasNextAndLastGames(): boolean {
    return this.hasNextGames && this.hasLastGames;
  }


}
