


















import {Component, Vue, Watch} from 'vue-property-decorator';
import {LeagueId} from '@/model/core/core-data.model';
import {SELECT_LEAGUE_ID, SELECTED_LEAGUE_ID} from '@/store/context.store';
import {Getter} from 'vuex-class';
import {LASTGAMES, LEAGUE_IDS} from '@/store/data/getters';
import { Prop } from 'vue-property-decorator';
import {IndexedGames} from '@/model/game/game.model';

@Component({
  components: {},
})
export default class ContextLeagueSelection extends Vue {

  @Prop(Boolean) showSingleElement?: boolean;
  @Prop(Boolean) lastGamesOnly?: boolean;

  @Getter(SELECTED_LEAGUE_ID) selectedLeagueId!: LeagueId;
  @Getter(LEAGUE_IDS) leagueIds!: LeagueId[];
  @Getter(LASTGAMES) lastgames!: IndexedGames;

  get isVisible() {
    const limit = this.showSingleElement ? 0 : 1;
    return this.leagueIds && this.leagueIds.length > limit;
  }

  league(leagueId: LeagueId) {
    if (this.isVisible && this.leagueIds && this.leagueIds.length === 1) {
      return this.$t(`leagueIdLong.${leagueId}`);
    }
    return this.$t(`leagueIdShort.${leagueId}`);
  }

  selectLeagueId(leagueId: LeagueId) {
    if (leagueId !== this.selectedLeagueId) {
      this.$store.commit(SELECT_LEAGUE_ID, leagueId);
    }
  }

  isActive(leagueId: LeagueId) {
    return this.selectedLeagueId === leagueId;
  }

  tooltipPosition(leagueId: LeagueId) {
    // note: tooltip disabled as it's transition effect causes hidden text during transition
    // see why: https://github.com/buefy/buefy/issues/736
    if (this.leagueIds.slice(-1)[0] === leagueId) {
      return 'is-left';
    }
    return 'is-right';
  }

  @Watch(LEAGUE_IDS, {immediate: true})
  onLeagueIdsChange(newLeagueIds: LeagueId[], oldLeagueIds: LeagueId[]) {
    const leagueIds = newLeagueIds.filter((leagueId, index, values) => this.selectedLeagueId === leagueId);
    if (leagueIds.length !== 0) {
      const leagueId = (this.leagueIds.length > 0 ? this.leagueIds[0] : undefined);
      if (!leagueId) {
        return;
      }
      const leagueIsInLastGames = Object.keys(this.lastgames).find(key => key === leagueId) !== undefined;
      if (!leagueIsInLastGames) {
        return;
      }
      this.selectLeagueId(leagueId);
    }
  }
}
