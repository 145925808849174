









import {Component, Prop, Vue} from 'vue-property-decorator';
import PanelBox from '@/components/ui/PanelBox.vue';

@Component({
  components: {PanelBox},
})
export default class LoremIpsum extends Vue {
  @Prop({type: Number, default: 1}) size?: number;
  @Prop({type: Boolean, default: false}) boxed?: boolean;
  @Prop({type: String, default: 'Lorem Ipsum'}) title?: string;

  get text(): string {
    const len = this.size || 1;
    let res = '';
    for (let i = 0; i < len; i++) {
      res += 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin ornare magna eros, ' +
        'eu pellentesque tortor vestibulum ut. Maecenas non massa sem. Etiam finibus odio quis feugiat facilisis.';
    }
    return res;
  }

  get styleClass(): string {
    return this.boxed ? 'box' : '';
  }

}
