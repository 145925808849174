








import {Component, Vue} from 'vue-property-decorator';
import dayjs from 'dayjs';
import { DATE_FORMAT_ISO } from '@/utils/date.util';
import { Getter } from 'vuex-class';
import { FOCUS_DATE } from '@/store/context.store';

@Component({
  components: {},
})
export default class MatchdayLinks extends Vue {

  @Getter(FOCUS_DATE) focusDate!: Date;

  matchdayDateRoute(date: Date) {
    return {name: 'route-matchday-date', params: {dateStr: dayjs(date).format(DATE_FORMAT_ISO)}};
  }
}
