export class Content {

	static stripTags(html: string): string {
		if (!html) {
			return html;
		}
		// credits to https://bit.ly/2L2CsHU
		let result = Content.convertEllipsis(html);
		result = result.replace(/<(?:.|\n)*?>/gm, '');
		result = result.replace(/\./g, '. ');
		result = result.replace(/ {2}/g, ' ');
		return result.trim();
	}

	static convertEllipsis(text: string): string {
		return text.replace('...', '…');
	}

}
