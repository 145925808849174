




import {Component, Inject, Prop, Vue} from 'vue-property-decorator';
import {ContentContainer} from '@/model/site/content.model';
import {ContentService} from '@/services/content.service';
import PanelBox from '@/components/ui/PanelBox.vue';
import {nil} from '@/utils/common.util';

@Component({
  components: {PanelBox},
})
export default class StaticContent extends Vue {

  @Prop({required: true, type: String}) staticId!: string;
  @Inject('contentService') contentService!: ContentService;

  container?: ContentContainer = nil();

  created() {
    this.contentService.staticContent(this.staticId)
      .then((container: ContentContainer) => {
        this.container = container;
      })
      .catch((ignore: any) => {
      });
  }

}
