





































import {Component, Vue} from 'vue-property-decorator';
import {GameInfo} from '@/model/game/game.model';
import {Getter} from 'vuex-class';
import {TOPGAME} from '@/store/data/getters';
import Loading from 'vue-loading-overlay';
import {hasKeys} from '@/utils/common.util';
import HfTeamLogo from '@/components/shared/HfTeamLogo.vue';
import PanelBox from '@/components/ui/PanelBox.vue';
import FavouriteTeamSelection from '@/components/layout/navigation/FavouriteTeamSelection.vue';

@Component({
  components: {FavouriteTeamSelection, PanelBox, HfTeamLogo, Loading},
})
export default class TopGame extends Vue {

  @Getter(TOPGAME) game!: GameInfo;

  isValid(game: any) {
    return hasKeys(game);
  }

}
