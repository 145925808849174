









import {Component, Vue} from 'vue-property-decorator';
import {Prop} from 'vue-property-decorator';

@Component({
  components: {},
})
export default class ShareThis extends Vue {

  @Prop() url?: string;
  @Prop({default: () => []}) networks?: string[];

  get shareUrl() {
    const ogUrl = document.getElementById('og:url');
    let url = undefined;
    if (this.url) {
      url = this.url;
    } else if (ogUrl && ogUrl.getAttribute('content')) {
      url = ogUrl.getAttribute('content');
    }
    return url;
  }

  created() {
    this.shareThis();
  }

  activated() {
    this.shareThis();
  }

  mounted() {
    this.shareThis();
  }

  updated() {
    this.shareThis();
  }

  shareThis() {
    if (this.shareUrl) {
      const w: any = window;
      let networks = this.networks;
      if (!networks || !networks.length) {
        networks = ['facebook', 'twitter', 'whatsapp'];
      }
      w.__sharethis__.load('inline-share-buttons', {
        id: 'hf-sharethis-buttons',
        alignment: 'right',
        enabled: true,
        font_size: 14.4,
        padding: 8,
        radius: 3,
        networks,
        size: 32,
        show_mobile_buttons: true,
        spacing: 8,
        url: this.shareUrl
      });
    } else {
      this.$log.warn('No ShareThis: "og:url" meta-tag not found');
    }
  }
}
